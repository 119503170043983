import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Header from '../components/Header/Header'
import Helmet from 'react-helmet'
import Button from '../components/Button/Button'

import ProjectFlowchart from '../components/ProjectFlowchart/ProjectFlowchart'
import IconServiceConsultingBw from '../images/svgs/icon_beratung_bw.svg'
import IconServiceRoadmapBw from '../images/svgs/icon_roadmap_bw.svg'
import IconServiceContractBw from '../images/svgs/icon_contract_bw.svg'
import IconPfeilAblauf from '../images/svgs/pfeil_ablauf.svg'
import ImgStageProjektstart from '../images/svgs/stage_projektstarten2.svg'
import '../styles/projekt-starten.css'

const ProjektStartenPage = ({ data }) => {
  const { labelIcon1, labelIcon2, labelIcon3, titleCalendly, titleCalendlySection, shortCalendly, titleFlowchart, titleFlowchartSection, titleIntrotext, titleStage} = data.contentfulStartProject
  const content_shortStageIntroText = data.contentfulStartProject.childContentfulStartProjectShortStageIntroTextTextNode.shortStageIntroText
  const content_shortIntrotext = data.contentfulStartProject.childContentfulStartProjectShortIntrotextTextNode.shortIntrotext
  const content_sublineFlowchart = data.contentfulStartProject.childContentfulStartProjectSublineFlowchartTextNode.sublineFlowchart

  return (
    <Layout>
      <Helmet
         bodyAttributes={{
           class: 'projekt-starten'
       }}
      />
      <SEO title={titleStage}>
      </SEO>
      <Header titleBig={titleStage} stageIntroText={content_shortStageIntroText} color='lila' textcolor='text-black' className='stage-projektstarten'>
        <ImgStageProjektstart />
      </Header>
      <div className='flex w-full xs:mb-0 xs:mt-16 md:mb-16 lg:my-24 px-6 md:px-8 justify-center'>
        <div className='flex w-full max-w-6xl flex-wrap'>
          <div className='flex flex-col xs:order-2 md:order-1 pr-8 lg:pr-32 md:w-8/12 max-w-4xl '>
            <p className='leading-relaxed'>{content_shortIntrotext}</p>
          </div>
          <div className='flex flex-col xs:order-1 md:order-2 md:w-4/12 xs:mb-8 md:mb-0 max-w-4xl '>
            <h2 className='leading-relaxed hyphens xl:w-11/12'>{titleIntrotext}</h2>
          </div>
        </div>
      </div>
      <div className='flex w-full flex-wrap px-6 md:px-16 mb-16 mt-8 justify-center'>
        <div className='flex w-full max-w-4xl flex-wrap justify-center'>
          <div class='w-full text-center md:w-1/5 md:text-left xs:pt-0 md:pt-0'>
              <div class='icon-wrapper flex items-center justify-center arrow-content-right md:justify-start'>
                <IconServiceConsultingBw className='mx-auto'/>
              </div>
              <span class='title-leistungen text-center block'>{labelIcon1}</span>
          </div>
          <div class='w-full text-center md:w-1/5 md:text-left xs:pt-0 p-6 hidden md:block'>
              <div class='icon-wrapper flex items-center justify-center arrow-content-right md:justify-start'>
                <IconPfeilAblauf className='mx-auto'/>
              </div>
          </div>
          <div className='w-full text-center md:w-1/5 md:text-left xs:pt-8 md:pt-0'>
              <div className='icon-wrapper flex items-center justify-center md:justify-start'>
                <IconServiceRoadmapBw className='mx-auto'/>
              </div>
              <span class='title-leistungen text-center block'>{labelIcon2}</span>
          </div>
          <div class='w-full text-center md:w-1/5 md:text-left xs:pt-0 p-6 hidden md:block'>
              <div class='icon-wrapper flex items-center justify-center arrow-content-right md:justify-start'>
                <IconPfeilAblauf className='mx-auto'/>
              </div>
          </div>
          <div class='w-full text-center md:w-1/5 md:text-left xs:pt-8 md:pt-0 '>
              <div class='icon-wrapper flex items-center justify-center md:justify-start'>
                <IconServiceContractBw className='mx-auto'/>
              </div>
              <span class='title-leistungen text-center block'>{labelIcon3}</span>
          </div>
        </div>
        <div className='flex w-full justify-center mt-24'>
          <IconPfeilAblauf className='mx-auto rotate-90'/>
        </div>
      </div>
      <div className='flex w-full flex-wrap xs:my-16 lg:my-16 px-0 justify-center '>
        <div className='flex w-full text-center'>
          <span className='category-title mx-auto text-center mb-2'>{titleFlowchartSection}</span>
        </div>
        <div className='flex w-full text-center max-w-3xl'>
          <h2 className='leading-relaxed mx-auto text-center mb-4'>{titleFlowchart}</h2>
        </div>
      </div>
      <div className='flex w-full justify-center'>
        <ProjectFlowchart />
      </div>
      <div className='flex w-full justify-center grey-section xs:p-4 md:p-0'>
        <div className='flex w-full flex-wrap xs:my-16 lg:my-16 pt-12 pb-0 max-w-6xl justify-center '>
          <div className='flex w-full text-center'>
            <span className='category-title mx-auto text-center mb-2'>{titleCalendlySection}</span>
          </div>
          <div className='flex w-full flex-wrap text-center max-w-3xl'>
            <h2 className='leading-relaxed mx-auto text-center'>{titleCalendly}</h2>
          </div>
          <div className='flex w-full flex-wrap text-center max-w-3xl mt-8'>
            <p>{shortCalendly}</p>
          </div>
          <div className='flex w-full flex-wrap mt-8 md:mt-0 text-center justify-center'>
            <Button href='https://calendly.com/swenden/kennenlernen' target='_blank' withArrow className='p-4 mr-8 mt-8 mt-0 btn-black btn-filled'>Jetzt Termin wählen</Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProjektStartenPage

export const ProjektStartenQuery = graphql`
query {
  contentfulStartProject(slug: {eq: "projekt-starten"}) {
    labelIcon1
    labelIcon2
    labelIcon3
    titleCalendly
    titleCalendlySection
    shortCalendly
    titleFlowchart
    titleFlowchartSection
    titleIntrotext
    titleStage
    childContentfulStartProjectShortStageIntroTextTextNode {
      shortStageIntroText
    }
    childContentfulStartProjectShortIntrotextTextNode {
      shortIntrotext
    }
    childContentfulStartProjectSublineFlowchartTextNode {
      sublineFlowchart
    }
  }
}

`

