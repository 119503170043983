import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import './ProjectFlowchart.css'
import ImgFeedback from '../../images/svgs/bg-ellipse-feedback.svg'

const ProjectFlowchart = (props) => {
    const data = useStaticQuery(graphql`
    query FlowchartQuery {
        contentfulProjectFlowchartItems(slug: {eq: "project-flowchart"}) {
            labelRow1
            labelRow2
            labelRow3
            labelRow4
            labelRow5
            labelRow6
            labelRow7
            labelRow8
            labelRow9
        }
    }
    `)

  return (
    <div class="projektablauf w-full">
    <div class="wrapper-diagramm">
        <div class="row">
        <div class="bar task1"><Link to="/projektablauf-beratung/" state={{modal: true}}><span class="bar-label">{data.contentfulProjectFlowchartItems.labelRow1}</span></Link></div>
        </div>
        <div class="row">
            <div class="dot task2"></div><Link to="/projektablauf-kickoff/" state={{modal: true}}><span class="bar-label-outside">{data.contentfulProjectFlowchartItems.labelRow2}</span></Link>
        </div>
        <div class="row">
            <div class="bar task3"><Link to="/projektablauf-konzept/" state={{modal: true}}><span class="bar-label">{data.contentfulProjectFlowchartItems.labelRow3}</span></Link></div>
        </div>
        <div class="row">
            <div class="bar task4"><Link className="link-task" to="/projektablauf-design/" state={{modal: true}}><span class="bar-label">{data.contentfulProjectFlowchartItems.labelRow4}</span></Link></div>
            <div class="bar task4-2"></div>
            <div class="bar task4-3 clearfix"></div>
        </div>
        <div class="row">
            <div class="bar task5"><Link to="/projektablauf-prototyp/" state={{modal: true}}><span class="bar-label">{data.contentfulProjectFlowchartItems.labelRow5}</span></Link></div>
        </div>
        <div class="row">
            <div class="bar task6"><Link to="/projektablauf-implementierung/" state={{modal: true}}><span class="bar-label">{data.contentfulProjectFlowchartItems.labelRow6}</span></Link></div>
        </div>
        <div class="row">
            <div class="bar task7"><Link to="/projektablauf-testing/" state={{modal: true}}><span class="bar-label">{data.contentfulProjectFlowchartItems.labelRow7}</span></Link></div>
        </div>
        <div class="row">
            <div class="dot task8"></div><Link to="/projektablauf-launch/" state={{modal: true}}><span class="bar-label-outside">{data.contentfulProjectFlowchartItems.labelRow8}</span></Link>
        </div>
        <div class="row">
            <div class="bar task9"><Link to="/projektablauf-support/" state={{modal: true}}><span class="bar-label">{data.contentfulProjectFlowchartItems.labelRow9}</span></Link></div>
        </div>
        <ImgFeedback className='feedback-bg'/>
    </div>


    <div class="wrapper-lines">
        <div class="row">
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
        </div>
        
    </div>
    
</div>
  )
}

export default ProjectFlowchart
